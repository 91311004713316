<template>
  <div class="person-center">
    <div class="top-box">
      <div class="top-header">
        <img v-if="false" class="avatar" src="" width="62" height="62" />
        <div v-else class="no-avatar"></div>
        <div class="right">
          <div class="name">钢铁佩奇</div>
          <span class="rank"><i>VIP</i>砖石</span>
          <span class="rank">享8.8折优惠</span>
        </div>
      </div>
      <div class="sumary">
        <div class="item">
          <p>99999</p>
          <p>会员积分</p>
        </div>
        <div class="item">
          <p>99999张</p>
          <p>优惠券</p>
        </div>
        <div class="item">
          <p>99999元</p>
          <p>储值金额</p>
        </div>
        <div class="item">
          <p>99999张</p>
          <p>计次卡</p>
        </div>
      </div>
    </div>
    <div class="list">
      <div class="list-item">
        <img width="20" height="20" src="../../assets/images/wechat-card/icon-order.svg" />
        <span class="title">我的订单</span>
        <i class="arrow-right"></i>
      </div>
      <div class="list-item">
        <img width="20" height="20" src ="../../assets/images/wechat-card/icon-privilege.svg" />
        <span class="title">会员特权</span>
        <i class="arrow-right"></i>
      </div>
      <div class="list-item">
        <img width="20" height="20" src ="../../assets/images/wechat-card/icon-rec.svg" />
        <span class="title">推荐有礼</span>
        <i class="arrow-right"></i>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import "./index.scss";
</style>
<script>

export default {
  data () {
    return {
    }
  },
  components: {},
  created () {
  },
  mounted () { },

  methods: {

  },
  computed: {
  },
  watch: {}
}
</script>
